.component-container {
  border: 1px solid #ccc;
  padding: 5px;

  h3 {
    margin-top: 0;
    font-size: 18px;
    color: #333;
  }

  .form-section {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    background-color: #f9f9f9;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input[type="text"],
    input[type="date"],
    textarea,
    select {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }

    /* 同樣對於 radio 按鈕的容器進行修改 */
    .radio-container {
      display: flex;
      flex-wrap: wrap;
      /* 允許換行 */
      gap: 15px;
      /* 增加 radio 之間的間隔 */
    }

    .radio-item {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .radio-item input[type="radio"] {
      margin-right: 5px;
      /* 增加 radio 按鈕與文字的間距 */
    }

    .radio-item label {
      cursor: pointer;
    }


    //Checkbox 元件
    .checkbox-container {
      display: flex;
      flex-wrap: wrap;
      /* 允許換行 */
      gap: 10px;
      /* 儲存格之間的間隔 */
    }

    .checkbox-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      /* 使 checkbox 和 label 都有點擊效果 */
    }

    .checkbox-item input[type="checkbox"] {
      cursor: pointer;
      /* 使 checkbox 本身顯示為可點擊的樣式 */
    }

    .checkbox-item label {
      margin-left: 5px;
      /* 增加 checkbox 和 label 之間的間距 */
      cursor: pointer;
      /* 使 label 顯示為可點擊的樣式 */
    }

    //簽名元件
    .signature-canvas {
      width: 100%;
      /* Canvas full width */
      height: 200px;
      /* You can adjust the height as needed */
      border: 1px solid #ccc;
      /* Optional border */
      background-color: #ffffff;
      /* Optional background color */
    }

    .clear-signature {
      padding: 5px 10px;
      /* Adjust padding */
      font-size: 14px;
      /* Adjust font size */
      margin-top: 10px;
      /* Add some space above */
      background-color: #ff6347;
      /* Red background */
      color: white;
      border: none;
      cursor: pointer;
    }


    .clear-signature:hover {
      background-color: #e55347;
      /* Darker red on hover */
    }

    .complete-signature {
      padding: 5px 10px;
      /* Adjust padding */
      font-size: 14px;
      /* Adjust font size */
      margin-top: 10px;
      /* Add some space above */
      background-color: #4caf50;
      /* Red background */
      color: white;
      border: none;
      cursor: pointer;
    }

    .complete-signature:hover {
      background-color: #3b893e;
      /* Darker red on hover */
    }


    /* 签核流程部分 */
    .step {
      margin-bottom: 15px;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;
      background-color: #f4f4f4;
    }

    .step strong {
      font-size: 16px;
    }

    .step span {
      font-weight: bold;
    }

    /* 为会签人员添加左右排列 */
    .step .approvers {
      display: flex;
      flex-wrap: wrap;
      /* 防止溢出，自动换行 */
      gap: 10px;
      /* 增加每个签核人之间的间距 */
    }

    .step .approver {
      display: inline-block;
      margin: 5px;
    }

    /* 会签状态颜色 */
    .step .approved {
      color: green;
    }

    .step .rejected {
      color: red;
    }

    .step .pending {
      color: gray;
    }

    .step button {
      margin-top: 5px;
      background-color: #4CAF50;
    }

    .step button:hover {
      background-color: #45a049;
    }


  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #45a049;
  }



}



.config-json-editor{
  width: 100%;
  margin: 10px 0;;
}
